body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.height_less_topbar {
    height: calc(100vh - 200px);
}
[role="columnheader"] {
    position: relative;
    overflow: visible !important;
}
[role="columnheader"] > div {
    display: flex;
    height: 100%;
    align-items: center;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .form-wrap {
        @apply bg-bluegray-200 dark:bg-ddark pt-10 pb-8 px-6 mt-8 rounded-lg ring-1 ring-bluegray-300 dark:ring-ldark shadow-lg relative
    }
    .form-wrap-heading {
        @apply absolute -top-4 text-lg bg-bluegray-200 dark:bg-ddark px-2 py-0.5 rounded-lg ring-1 ring-bluegray-300 dark:ring-ldark text-tcolor font-medium
    }
    button {
        @apply focus:outline-none
    }
}

.resize_div::after{
    content: "";
    background-color: transparent;
    width: 97%;
    height: 100%;
    /* z-index: 9999; */
    position: absolute;
    left: 0;

    /* height:100vh;width:100vw;max-height:none; */
}

@layer utilities {
    .no-transform {
        transform: none !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
    }
}